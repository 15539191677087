import React, { Component } from "react";
import {
  apiURL,
  change_date_format,
  get_changed_time,
  sanitizeHtml,
} from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { Editor } from "@tinymce/tinymce-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Delete from "../delete/Delete";
import ClearIcon from "@material-ui/icons/Clear";

import EditIcon from "@material-ui/icons/Edit";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import "../style/Style.css";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
// import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import FeedbackIcon from "@material-ui/icons/Feedback";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Meta from "../meta/Meta";
import XLSX from "xlsx";
import ExportExcelButton from "../utils/ExportExcelButton";
import PublishIcon from "@material-ui/icons/Publish";
const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({
//     textField: {
//         height:'20px'
//     },
// }))

const useStyles = (theme) => ({
  buttonEdit: {
    textTransform: "capitalize",
    border: "1px solid #035eab",
    borderRadius: "0px",
    marginRight: "10px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 650,
  },
});
class Training_schedule extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      // drawerOpen: false,
      drawerOpen: true,
      showDialog: false,
      selecteDate: new Date(),
      changeDate: "",
      dialogTitle: "",
      file: null,
      event_file: null,
      imagePreview: null,
      eventImagePreview: null,
      imageEdit: false,
      eventImageEdit: false,
      page: 1,
      per: 20,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      mode: "",
      editorVlaue: "",
      selecteDate: new Date(),
      changeDate: "",
      selectFromTime: new Date(),
      selectToTime: new Date(),
      speakerData: [],
      trainingScheduleData: [],
      //speaker: '',
      speaker: [],
      noData: "",
      video_url: "",
      imageGallery: [],
      imageGalleryPreview: [],
      imageGalleryEdit: false,
      eventGallery: [],
      language: "",
      contacts: [],
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.addDialog = this.addDialog.bind(this);
    this.editDialog = this.editDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFromTimeChange = this.handleFromTimeChange.bind(this);
    this.handleToTimeChange = this.handleToTimeChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.trainingSchudeleListData = this.trainingSchudeleListData.bind(this);
    this.handleMultipleImageChange = this.handleMultipleImageChange.bind(this);
    this.removeImageGallery = this.removeImageGallery.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.speakerListData();
    this.trainingSchudeleListData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  trainingSchudeleListData = () => {
    this.setState({ loading: true });
    const { per, page, trainingScheduleData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      search: document.getElementById("search").value,
    };
    axios
      .post(
        apiURL("training_schedule?per_page=" + per + "&page=" + page),
        body,
        { headers: headers }
      )
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const updatedData = [
              ...this.state.trainingScheduleData,
              ...resultData.result,
            ];
            this.setState({
              trainingScheduleData: updatedData,

              scrolling: false,
              total_pages: resultData.total_pages,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({
              loading: false,
              scrolling: false,
              noData: "No Training Schedule Found",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: true,
        }),
        this.trainingSchudeleListData
      );
    }
  };
  handleMultipleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const previews = files.map((file) => URL.createObjectURL(file));

    this.setState((prevState) => ({
      imageGallery: [...prevState.imageGallery, ...files],
      imageGalleryPreview: [...prevState.imageGalleryPreview, ...previews],
      imageGalleryEdit: true,
    }));
  };
  removeImageGallery = (index) => {
    const { imageGalleryPreview, imageGallery } = this.state;
    imageGalleryPreview.splice(index, 1);
    imageGallery.splice(index, 1);
    this.setState({
      imageGalleryPreview,
      imageGallery,
      imageEdit: false,
      eventGallery: [],
    });
  };
  removeImageEditGallery = (index) => {
    const { eventGallery, imageGallery } = this.state;
    eventGallery.splice(index, 1);
    imageGallery.splice(index, 1);
    this.setState({
      eventGallery,
      imageGallery,
      imageEdit: false,
    });
  };
  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector("Table");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };

  speakerListData = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("training_schedule/speaker"), { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              speakerData: resultData.result,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  changeHandler = (e, type) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleImageChange = (e) => {
    this.setState({
      imagePreview: e !== null ? URL.createObjectURL(e.target.files[0]) : "",
      file: e !== null ? e.target.files[0] : "",
      imageEdit: true,
    });
  };
  handleEventImageChange = (e) => {
    this.setState({
      eventImagePreview:
        e !== null ? URL.createObjectURL(e.target.files[0]) : "",
      event_file: e !== null ? e.target.files[0] : "",
      eventImageEdit: true,
    });
  };
  removeImage = (e) => {
    this.setState({
      imagePreview: "",
      file: "",
      imageEdit: false,
      image: "",
    });
  };
  removeEventImage = (e) => {
    this.setState({
      eventImagePreview: "",
      event_file: "",
      eventImageEdit: false,
      eventImage: "",
    });
  };
  addDialog = (e) => {
    this.setState({
      mode: "add",
      showDialog: true,
      dialogTitle: "Add Training Schedule",
    });
  };

  editDialog = (e) => {
    this.setState({
      mode: "edit",
      showDialog: true,
      dialogTitle: "Edit Training Schedule",
      id: e.fldi_id,
      title: e.fldv_title,
      // speaker: e.fldi_speaker_id,
      speaker: e.fldv_speaker_name,
      selecteDate: new Date(e.fldv_select_date),
      selectFromTime: new Date(e.fldv_from_time),
      selectToTime: new Date(e.fldv_to_time),
      editorVlaue: e.fldv_description,
      venue: e.fldv_venue_location,
      duration: e.fldv_duration,
      language: e.fldv_language,
      image: e.fldv_image_url,
      eventImage: e.fldv_event_image,
      eventGallery:
        e.fldv_file_urls !== "" && e.fldv_file_urls !== "null"
          ? JSON.parse(e.fldv_file_urls)
          : [],

      video_url: e.fldv_video_url,
    });
  };
  closeDialog = () => {
    this.setState({
      showDialog: false,
      title: "",
      speaker: [],
      // speaker: '',
      selecteDate: new Date(),
      selectFromTime: new Date(),
      selectToTime: new Date(),

      image: "",
      eventImage: "",
      file: "",
      event_file: "",
      video_url: "",
      venue: "",
      duration: "",
      eventGallery: [],
      imageGallery: [],
    });
  };

  handleDateChange = (date) => {
    console.log(date);
    this.setState({
      selecteDate: new Date(date),
      changeDate: change_date_format(date),
    });
  };

  handleFromTimeChange = (date) => {
    console.log(date);
    this.setState({
      selectFromTime: new Date(date),
      changeDate: change_date_format(date),
    });
  };

  handleToTimeChange = (date) => {
    console.log(date);
    this.setState({
      selectToTime: new Date(date),
      changeDate: change_date_format(date),
    });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      editorVlaue: content,
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("speaker_name", this.state.speaker);
    formData.append("select_date", this.state.selecteDate);
    formData.append(
      "change_date",
      change_date_format(this.state.selecteDate) +
        " " +
        get_changed_time(this.state.selecteDate, false)
    );
    formData.append("from_time", this.state.selectFromTime);
    //formData.append('changed_from_time', get_changed_time(this.state.selectFromTime));
    formData.append("to_time", this.state.selectToTime);
    formData.append("duration", this.state.duration);
    formData.append("venue_location", this.state.venue);
    formData.append("language", this.state.language);
    //formData.append('changed_to_time', get_changed_time(this.state.selectToTime));
    formData.append("description", this.state.editorVlaue);
    formData.append("file", this.state.file);
    formData.append("event_image", this.state.event_file);
    // this.state.imageGallery.forEach((image) => {
    //   formData.append("event_file[]", image);
    // });
    this.state.imageGallery.map((file) => {
      formData.append("event_file[]", file);
    });
    // this.state.imageGallery.forEach((image) => {
    //   formData.append("event_file", image);
    // });
    // for (let i = 0; i < this.state.imageGallery.length; i++) {
    //   formData.append("event_file[]", this.state.imageGallery[i]);
    // }
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("training_schedule/add"), formData, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.closeDialog();
          this.componentDidMount();
          this.setState({ imageEdit: false, imagePreview: null, file: null });
          this.setState({
            eventImageEdit: false,
            eventImagePreview: null,
            event_file: null,
            imageGallery: [],
            imageGalleryPreview: [],
            imageGalleryEdit: false,
          });
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onEditFormSubmit = (e) => {
    e.preventDefault();

    const editFormData = new FormData();
    editFormData.append("id", this.state.id);
    editFormData.append("title", this.state.title);
    editFormData.append("speaker_name", this.state.speaker);
    editFormData.append("select_date", this.state.selecteDate);
    editFormData.append(
      "change_date",
      change_date_format(this.state.selecteDate) +
        " " +
        get_changed_time(this.state.selecteDate, false)
    );
    editFormData.append("from_time", this.state.selectFromTime);
    //editFormData.append('changed_from_time', get_changed_time(this.state.selectFromTime));
    editFormData.append("to_time", this.state.selectToTime);
    editFormData.append("duration", this.state.duration);
    editFormData.append("venue_location", this.state.venue);
    editFormData.append("language", this.state.language);
    //editFormData.append('changed_to_time', get_changed_time(this.state.selectToTime));
    editFormData.append("description", this.state.editorVlaue);
    if (this.state.file !== null) {
      editFormData.append("file", this.state.file);
    }
    editFormData.append("exist_file", this.state.image);
    editFormData.append("image_change", this.state.imageEdit);
    if (this.state.event_file !== null) {
      editFormData.append("event_image", this.state.event_file);
    }
    editFormData.append("exist_event_file", this.state.eventImage);
    editFormData.append("event_image_change", this.state.eventImageEdit);
    this.state.imageGallery.map((file) => {
      editFormData.append("event_file[]", file);
    });
    editFormData.append("existing_file_urls", this.state.eventGallery);
    // this.state.eventGallery.map((file) => {
    //   editFormData.append("existing_file_urls", file);
    // });
    editFormData.append("video_url", this.state.video_url);
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("training_schedule/edit"), editFormData, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ imageEdit: false, imagePreview: null, file: null });
          this.setState({
            eventImageEdit: false,
            eventImagePreview: null,
            event_file: null,
            imageGallery: [],
            imageGalleryPreview: [],
            imageGalleryEdit: false,
          });
          this.closeDialog();
          this.componentDidMount();
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeStatus = (id, status) => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("training_schedule/change_status/" + id + "/" + status), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedTrainingSchedule = this.state.trainingScheduleData.map(
            (ts) => {
              if (ts.fldi_id === id) {
                const _ts = { ...ts };
                _ts.flg_status = status === "0" ? "1" : "0";
                return _ts;
              }
              return ts;
            }
          );
          this.setState({ trainingScheduleData: updatedTrainingSchedule });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchHandler = (e) => {
    this.setState({ page: 1, searchValue: e.target.value }, () => {
      this.trainingSchudeleListData();
    });
    //this.trainingSchudeleListData();
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  successDelete = (e, id, index) => {
    if (e === true) {
      const deleteData = this.state.trainingScheduleData;
      deleteData.splice(index, 1);
      this.setState({
        trainingScheduleData: deleteData,
        showSnackbar: true,
        snackBarMessage: "data deleted suceesfully",
        severityColor: "success",
        snackBarTimer: 3000,
      });
    }
  };

  exportFile() {
    let headers = [
      [
        "Title",
        "Speaker",
        "Schedule Date",
        "From Time",
        "To Time",
        "Status",
        "Delete",
      ],
    ];
    this.state.trainingScheduleData.forEach((value) => {
      const arrSpeakerPush = [];
      {
        value.speaker.map((arrSpeaker, index) => {
          arrSpeakerPush.push(arrSpeaker.speaker_name);
        });
      }

      let valueArray = [
        value.fldv_title,
        value.fldv_speaker_name,
        value.fldv_changed_date,

        get_changed_time(value.fldv_from_time, true),
        get_changed_time(value.fldv_to_time, true),
        value.fldv_duration,

        value.flg_status === "1" ? "Active" : "Deactive",
        value.flg_is_deleted === "1" ? "Not Delete" : "Deleted",
      ];
      headers.push(valueArray);
    });

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.book_append_sheet(wb, wsAll, "Training Schedule");
    XLSX.writeFile(wb, "export-training-schedule.xlsx");
  }
  handleFileUpload(e, fldi_id) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the contact column is in the first sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headerRow = jsonData[0];
        const contactIndex = headerRow.findIndex((col) => col === "Contact");

        if (contactIndex !== -1) {
          const contacts = jsonData.slice(1).map((row) => row[contactIndex]);

          this.setState({ contacts });
          this.saveData(fldi_id, contacts);
          this.setState({
            showSnackbar: true,
            snackBarMessage: "File uploaded successfully",
            severityColor: "success",
            snackBarTimer: 3000,
          });
        } else {
          console.error("Contact column not found");
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please try again",
            severityColor: "error",
            snackBarTimer: 3000,
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  }
  saveData = (id, contacts) => {
    console.log(id);
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      contact_numbers: contacts,
      event_id: id,

      event_type: "past_training",
    };
    axios
      .post(apiURL("Achievement/upload_and_insert_attendees"), body, {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === "success") {
            const index = this.state.trainingScheduleData.findIndex(
              (event) => event.fldi_id === id
            );

            if (index !== -1) {
              const updatedEventsList = [...this.state.trainingScheduleData];
              updatedEventsList[index].flg_is_file_uploaded = "1";

              this.setState({
                trainingScheduleData: updatedEventsList,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };

    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <React.Fragment>
        <Helmet>
          <title>Training Schedule</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      <TextField
                        className="textField"
                        type="text"
                        placeholder="...Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          style: {
                            color: "#414141",
                            backgroundColor: "rgba(255, 255, 255, 0.29)",
                          },
                        }}
                        size="small"
                        id="search"
                        variant="outlined"
                        style={{ fontColor: "#fff", marginRight: "1rem" }}
                        name="search"
                        onChange={(e) => this.searchHandler(e)}
                      />
                      <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={(e) => this.addDialog(e)}
                      >
                        {" "}
                        Add Training Schedule{" "}
                      </Button>
                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      />
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>Training Schedule </h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell> Title</TableCell>
                            {/*<TableCell>Description</TableCell>*/}
                            <TableCell>Speaker</TableCell>
                            <TableCell>Schedule Date</TableCell>
                            <TableCell>From Time</TableCell>
                            <TableCell>To Time</TableCell>
                            {/*<TableCell>Image</TableCell>*/}
                            <TableCell>No of registrations</TableCell>
                            <TableCell>No of Shares</TableCell>
                            <TableCell>No of Bookmarks</TableCell>
                            <TableCell>Upload Certificate</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.trainingScheduleData.map(
                            (arrList, index) => {
                              const fileInputRef = React.createRef();
                              return (
                                <TableRow key={arrList.fldi_id}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{arrList.fldv_title}</TableCell>
                                  {/*<TableCell>{sanitizeHtml(arrList.fldv_description)}</TableCell>*/}
                                  {/*<TableCell>{arrList.speaker_name}</TableCell>*/}
                                  <TableCell>
                                    {arrList.fldv_speaker_name}
                                  </TableCell>
                                  <TableCell>
                                    {arrList.fldv_changed_date}
                                  </TableCell>
                                  <TableCell>
                                    {get_changed_time(
                                      arrList.fldv_from_time,
                                      true
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {get_changed_time(
                                      arrList.fldv_to_time,
                                      true
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {arrList.no_of_register_user}
                                  </TableCell>
                                  <TableCell>{arrList.no_of_share}</TableCell>
                                  <TableCell>
                                    {arrList.no_of_bookmark}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      className={classes.buttonEdit}
                                      variant="outlined"
                                      color="default"
                                      disabled={
                                        arrList.flg_is_file_uploaded === "1"
                                      }
                                      startIcon={
                                        <PublishIcon
                                          style={{
                                            color:
                                              arrList.flg_is_file_uploaded ===
                                              "1"
                                                ? "#B0B0B0"
                                                : "#035eab",
                                            fontSize: "1rem",
                                          }}
                                        />
                                      }
                                      onClick={() =>
                                        fileInputRef.current.click()
                                      }
                                    >
                                      Upload
                                      <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        hidden
                                        onChange={(e) =>
                                          this.handleFileUpload(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                        ref={fileInputRef}
                                      />
                                    </Button>
                                  </TableCell>
                                  {/*<TableCell><img style={{width:"40px"}} src={arrList.fldv_image_url} alt={arrList.fldv_name} /></TableCell>*/}
                                  <TableCell>
                                    {" "}
                                    <Switch
                                      size="small"
                                      checked={arrList.flg_status === "1"}
                                      color="primary"
                                      onChange={() =>
                                        this.changeStatus(
                                          arrList.fldi_id,
                                          arrList.flg_status
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      className={classes.buttonEdit}
                                      variant="outlined"
                                      color="default"
                                      startIcon={
                                        <EditIcon
                                          style={{
                                            color: "#035eab",
                                            fontSize: "1rem",
                                          }}
                                        />
                                      }
                                      onClick={() => this.editDialog(arrList)}
                                    >
                                      Edit
                                    </Button>
                                    <Link
                                      to={
                                        "training-feedback/" +
                                        arrList.fldi_id +
                                        "/" +
                                        arrList.fldv_title
                                          .replace(/ /g, "-")
                                          .toLowerCase()
                                      }
                                    >
                                      {" "}
                                      <Button
                                        className={classes.buttonEdit}
                                        variant="outlined"
                                        color="default"
                                        startIcon={
                                          <FeedbackIcon
                                            style={{
                                              color: "#035eab",
                                              marginLeft: "5PX",
                                              fontSize: "0.875rem",
                                            }}
                                          />
                                        }
                                      >
                                        Feedback
                                      </Button>
                                    </Link>
                                    <Link
                                      to={
                                        "join-training-user/" + arrList.fldi_id
                                      }
                                    >
                                      <Button
                                        className={classes.buttonEdit}
                                        variant="outlined"
                                        color="default"
                                        startIcon={
                                          <GroupAddIcon
                                            style={{
                                              color: "#035eab",
                                              marginLeft: "5px",
                                            }}
                                          />
                                        }
                                        onClick={() => this.editDialog(arrList)}
                                      >
                                        Join Users
                                      </Button>
                                    </Link>

                                    <Delete
                                      deleteID={arrList.fldi_id}
                                      table={"training_schedule"}
                                      onSuccessDelete={(e) =>
                                        this.successDelete(
                                          e,
                                          arrList.fldi_id,
                                          index
                                        )
                                      }
                                    />

                                    <Meta
                                      primaryId={arrList.fldi_id}
                                      useFor={"inner_page"}
                                      table={"training_schedule"}
                                      onSuccessDelete={(e) =>
                                        this.successDelete(
                                          e,
                                          arrList.fldi_id,
                                          index
                                        )
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                  >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.scrolling ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>

        <Dialog
          open={this.state.showDialog}
          fullWidth={true}
          maxWidth={"md"}
          disableEnforceFocus
        >
          <form
            id="courses"
            onSubmit={
              this.state.mode === "edit"
                ? (e) => this.onEditFormSubmit(e)
                : (e) => this.onFormSubmit(e)
            }
          >
            <DialogTitle id="form-dialog-title">
              <h3 style={{ textAlign: "center" }}>{this.state.dialogTitle}</h3>
            </DialogTitle>
            <DialogContent style={{ overflowY: "hidden" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Title"
                    variant="outlined"
                    name="title"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.title}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Speaker"
                    variant="outlined"
                    name="speaker"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.speaker}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl size="small" style={{ width: "100%" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        size="small"
                        inputVariant="outlined"
                        label="Choose Date"
                        value={this.state.selecteDate}
                        onChange={(date) => this.handleDateChange(date)}
                        name="date"
                        format="dd/MM/yyyy"
                        showTodayButton
                        // minDate={new Date(this.state.selecteDate)}
                        autoOk
                        variant="inline"
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    // required
                  >
                    <InputLabel>Language</InputLabel>
                    <Select
                      name="language"
                      value={this.state.language}
                      onChange={(e) => this.changeHandler(e)}
                      label="Language"
                      // required
                    >
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Marathi">Marathi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      size="small"
                      style={{ width: "100%" }}
                      ampm={true}
                      variant="inline"
                      inputVariant="outlined"
                      label="Start Time"
                      value={this.state.selectFromTime}
                      onChange={(date) => this.handleFromTimeChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      size="small"
                      style={{ width: "100%" }}
                      ampm={true}
                      variant="inline"
                      inputVariant="outlined"
                      label="End Time"
                      value={this.state.selectToTime}
                      onChange={(date) => this.handleToTimeChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Venue"
                    variant="outlined"
                    name="venue"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.venue}
                    // required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Duration"
                    variant="outlined"
                    name="duration"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.duration}
                    required
                  />
                </Grid>
                {this.state.mode === "edit" ? (
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label="Video Url"
                      variant="outlined"
                      name="video_url"
                      onChange={(e) => this.changeHandler(e)}
                      value={this.state.video_url}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12}>
                  <Editor
                    initialValue={this.state.editorVlaue}
                    init={{
                      height: 188,
                      selector: "textarea", // change this value according to your HTML
                      convert_urls: false,
                      menubar: true,

                      plugins: [
                        "advlist autolink tinydrive lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      api_key:
                        "v2xbwrmnf5ixj35bcuymns7odhg03cvh4xuyud4wc0zjc0lh",
                      tinydrive_token_provider:
                        "https://navdishaadmin.navneet.com/jwt/text_editor_key/api/jwt.php",
                      toolbar:
                        "undo redo | formatselect | bold italic underline backcolor | \
                                           alignleft aligncenter alignright alignjustify | \
                                           bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(e) => this.handleEditorChange(e)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={8}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    {this.state.imagePreview || this.state.image ? (
                      <ClearIcon onClick={(e) => this.removeImage(e)} />
                    ) : (
                      ""
                    )}
                    {this.state.imageEdit === true ? (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.imagePreview}
                      />
                    ) : (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.image}
                      />
                    )}
                    <Button
                      variant="contained"
                      startIcon={<ImageSearchIcon />}
                      color="primary"
                      component="label"
                    >
                      Select Main Image
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleImageChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    {this.state.eventImagePreview || this.state.eventImage ? (
                      <ClearIcon onClick={(e) => this.removeEventImage(e)} />
                    ) : (
                      ""
                    )}
                    {this.state.eventImageEdit === true ? (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.eventImagePreview}
                      />
                    ) : (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.eventImage}
                      />
                    )}
                    <Button
                      variant="contained"
                      startIcon={<ImageSearchIcon />}
                      color="primary"
                      component="label"
                      style={{ marginLeft: "2px" }}
                    >
                      Select Event Creative
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleEventImageChange}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {this.state.imageGalleryEdit === true ? (
                      <React.Fragment>
                        {this.state.imageGalleryPreview.map(
                          (preview, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <img
                                src={preview}
                                alt="Preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                              <IconButton
                                size="small"
                                onClick={() => this.removeImageGallery(index)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  color: "black",
                                  background: "white",
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </div>
                          )
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {this.state.eventGallery.map((preview, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={preview}
                              alt="Preview"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                            <IconButton
                              size="small"
                              onClick={() => this.removeImageGallery(index)}
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: "black",
                                background: "white",
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="multiple-file-upload"
                    multiple
                    type="file"
                    onChange={this.handleMultipleImageChange}
                  />
                  <label htmlFor="multiple-file-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Select Image Gallery
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="buttonCancel">
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => this.closeDialog(e)}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {" "}
                Submit{" "}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Training_schedule);
