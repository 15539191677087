import React, { Component } from 'react';
import { IconButton, Badge, Popover, Typography } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles, withStyles } from '@material-ui/core/styles'; 
import axios from 'axios'; 
import { apiURL } from '../utils'; 

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(6),
      },
}));

class Notification_data extends Component {
    _isNotificationMounted = false;
    constructor(props){
        super(props); 
        this.state = {
            popoverOpen: null,
            notificationCount: 0,
            notificationData: [],
        }
        this.getNotification      = this.getNotification.bind(this);
        this.notificationClick    = this.notificationClick.bind(this);
        this.updateNotification   = this.updateNotification.bind(this);
    } 

    componentDidMount() { 
        this._isNotificationMounted = true;
        this.getNotification();
        this.setState({
            notificationCount: 0,
            notificationData: [],
        })
    }

    componentWillUnmount() {
        this._isNotificationMounted = false;
    }

    
   componentWillReceiveProps(prevProps, prevStates) { 
    if (prevStates.notificationData !== this.state.notificationData && prevStates.notificationCount != this.state.notificationCount) {
      this.setState({
        notificationData:this.state.notificationData,
        notificationCount: this.state.notificationCount, 
      }, () => { 
       console.log(this.state.notificationCount)
       console.log(this.state.notificationData)
      this.componentDidMount();
      this.getNotification();
     }) 
    }   
  }

  
  componentDidUpdate(prevProps, prevStates) { 
    if (prevStates.notificationData !== this.state.notificationData && prevStates.notificationCount != this.state.notificationCount) {
      this.setState({
        notificationData:this.state.notificationData,
        notificationCount: this.state.notificationCount, 
      }, () => { 
          console.log('notifiaction update')
       console.log(this.state.notificationCount)
       console.log(this.state.notificationData)
      this.componentDidMount(); 
     }) 
    }   
  }
  

    getNotification = () => {
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('notification'), {headers: headers})
        .then(response => {
            if(this._isNotificationMounted) {
            const noticationResult = response.data;
            if(noticationResult.status === true)
            { 
                this.setState({ 
                    notificationCount: noticationResult.result.length,
                    notificationData: noticationResult.result
                 });
            }
            else{
                this.setState({ 
                    notificationCount: 0,
                    notificationData: []
                 });
            }
            }
        })
        .catch(error => {
            console.log(error)
            console.log('notificaation error')
            this.setState({ 
                notificationCount: 0,
                notificationData: []
             });
        })
    }

    updateNotification = (id) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('notification/update/'+id), {headers: headers})
        .then(response => {
            const resultData = response.data;
            console.log(resultData);
            if(resultData.status === true || resultData.status === 1)
            { 
                const count =  this.state.notificationData.length; 
                this.setState({ 
                    notificationCount: (count - 1), 
                 });
                 this.componentDidMount();
            }
        })
        .catch(error => {
            console.log(error)
            console.log('notificaation error')
        })
    }

    notificationClick = (event) => { 
        this.setState({ 
            popoverOpen: event.currentTarget
        }); 
    }

    notificationClose = (event) => {
        this.setState({
            popoverOpen: null
        });
    }

    render(){
        const open = Boolean(this.state.popoverOpen);
        const id = open ? 'simple-popover' : undefined;
        //const { classes } = this.props; 
        const classes = this.props.classes 
        return (
            <div>
                <IconButton color="inherit" aria-describedby={id} onClick={(e) => this.notificationClick(e)} >   
                    <Badge color="secondary" aria-describedby="notification" badgeContent={this.state.notificationCount} >
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Popover 
                    open={open && this.state.notificationData.length > 0} 
                    onClose={(e) => this.notificationClose(e) } 
                    anchorEl={this.state.popoverOpen}  
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center', 
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    >
                    { this.state.notificationCount > 0 && this.state.notificationData.map((arrList, index) => {    
                     return <Typography key={index} style={{padding:'1px 10px'}} onClick={() => this.updateNotification(arrList.fldi_id)} >{arrList.fldv_key}</Typography>
                    })}
                </Popover>
            </div>
        )
    }
}

export default (withStyles)(useStyles)(Notification_data);