import React, { Component } from "react";
import { apiURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  Dialog,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Helmet from "react-helmet";
import Delete from "../delete/Delete";
import PageviewIcon from "@material-ui/icons/Pageview";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
//import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";
//import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import XLSX from "xlsx";
import ExportExcelButton from "../utils/ExportExcelButton";

const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = (theme) => ({
  buttonEdit: {
    textTransform: "capitalize",
    border: "1px solid #035eab",
    borderRadius: "0px",
    marginRight: "10px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 650,
  },
});

class Contact_us_enquiry extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      // drawerOpen: false,
      drawerOpen: true,
      contactData: [],
      page: 1,
      per: 20,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      noData: "",
      viewDiallogData: false,
      viewReplyDiallogData: false,
      topic: "",
      message: "",
      reply: "",
      data: [],
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.ViewData = this.ViewData.bind(this);
    this.ViewReplyData = this.ViewReplyData.bind(this);
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.getInTouchListData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getInTouchListData = () => {
    this.setState({ loading: true });
    const { per, page, contactData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .post(apiURL("contact_us_enquiry?per_page=" + per + "&page=" + page), {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              contactData:
                resultData.total < 20
                  ? resultData.result
                  : [...this.state.contactData, ...resultData.result],
              scrolling: false,
              total_pages: resultData.total_pages,
            });
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false, noData: "no Data Found" });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: true,
        }),
        this.articleListData
      );
    }
  };

  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector("Table");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          this.loadMore();
        }
      }
    }
  };

  changeStatus = (id, status) => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("contact_us_enquiry/change_status/" + id + "/" + status), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedContactData = this.state.contactData.map((cue) => {
            if (cue.fldi_id === id) {
              const _cue = { ...cue };
              _cue.flg_status = status === "0" ? "1" : "0";
              return _cue;
            }
            return cue;
          });
          this.setState({ contactData: updatedContactData });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  ViewData = (e) => {
    this.setState({
      viewDiallogData: true,
      data: e,
      name: e.fldv_name,
      email: e.fldv_email,
      contact: e.fldv_contact,
      topic: e.fldv_topic,
      message: e.fldt_message,
    });
  };
  ViewReplyData = (e) => {
    this.setState({
      viewReplyDiallogData: true,
      data: e,
      name: e.fldv_name,
      email: e.fldv_email,
      contact: e.fldv_contact,
      topic: e.fldv_topic,
      message: e.fldt_message,
      reply: e.fldv_reply_message,
    });
  };
  closeDialog = (e) => {
    this.setState({
      viewDiallogData: false,
      name: "",
      email: "",
      contact: "",
      topic: "",
      message: "",
      reply: "",
    });
  };
  handleClose = () => {
    this.setState({
      viewDiallogData: false,
      name: "",
      email: "",
      contact: "",
      topic: "",
      message: "",
    });
  };
  handleReplyClose = () => {
    this.setState({
      viewReplyDiallogData: false,
      name: "",
      email: "",
      contact: "",
      topic: "",
      message: "",
      reply: "",
    });
  };
  successDelete = (e, id, index) => {
    if (e === true) {
      const deleteData = this.state.contactData;
      deleteData.splice(index, 1);
      this.setState({
        contactData: deleteData,
        showSnackbar: true,
        snackBarMessage: "data deleted suceesfully",
        severityColor: "success",
        snackBarTimer: 3000,
      });
    }
  };
  changeHandler = (e, type) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  exportFile() {
    let headers = [["Name", "Email", "Contact", "Message", "Status", "Delete"]];
    this.state.contactData.forEach((value) => {
      let valueArray = [
        value.fldv_name,
        value.fldv_email,
        value.fldv_contact,
        value.fldt_message,
        value.flg_status === "1" ? "Active" : "Deactive",
        value.flg_is_deleted === "1" ? "Not Delete" : "Deleted",
      ];
      headers.push(valueArray);
    });

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.book_append_sheet(wb, wsAll, "Contact Us Enquiry");
    XLSX.writeFile(wb, "export-contact-us-enquiry.xlsx");
  }
  truncateMessage = (message, maxLength) => {
    if (message.length <= maxLength) return message;
    return message.substring(0, maxLength) + "...";
  };
  onFormSubmit = (e, values) => {
    e.preventDefault();
    console.log(values);
    const body = {
      id: values.fldi_id,
      name: values.fldv_name,
      email: values.fldv_email,
      message: this.state.reply,
      category: values.fldv_topic,
    };
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("contact_us_enquiry/reply"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.closeDialog();
          this.componentDidMount();
          const index = this.state.contactData.findIndex(
            (event) => event.fldi_id === body.id
          );
          if (index !== -1) {
            const updatedData = [...this.state.contactData];
            updatedData[index].fldv_reply_status = "1";
            updatedData[index].fldv_reply_message = body.message;
            this.setState({
              contactData: updatedData,
            });
          }

          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { classes } = this.props;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact Us Enquiry</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      {/*<TextField className="textField" type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    /> 
                                    <Button className={classes.buttonAdd} variant="contained" color="primary" startIcon={<AddCircleOutlineOutlinedIcon/>}  onClick={this.addDialog}> Add Video </Button>  */}
                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      />
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>Contact Us Enquiry </h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableCell style={{ width: "2rem" }}>Id</TableCell>
                          <TableCell> Name</TableCell>
                          <TableCell> Email</TableCell>
                          <TableCell> Contact</TableCell>
                          <TableCell> Subject</TableCell>
                          <TableCell>Message Preview</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Reply</TableCell>
                          <TableCell style={{ width: "3rem" }}>
                            Action
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {this.state.contactData.map((arrList, index) => {
                            return (
                              <TableRow key={arrList.fldi_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{arrList.fldv_name}</TableCell>
                                <TableCell>{arrList.fldv_email}</TableCell>
                                <TableCell>{arrList.fldv_contact}</TableCell>
                                <TableCell>{arrList.fldv_topic}</TableCell>
                                <TableCell
                                  onClick={() => this.ViewData(arrList)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {this.truncateMessage(
                                    arrList.fldt_message,
                                    20
                                  )}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  {arrList.fldv_reply_status === "1"
                                    ? "Replied"
                                    : "Pending"}
                                </TableCell>
                                <TableCell
                                  onClick={() => this.ViewReplyData(arrList)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {this.truncateMessage(
                                    arrList.fldv_reply_message,
                                    20
                                  )}
                                </TableCell>
                                <TableCell>
                                  {/* <Button
                                    className={classes.buttonEdit}
                                    variant="outlined"
                                    color="default"
                                    startIcon={
                                      <PageviewIcon
                                        style={{
                                          color: "#035eab",
                                          marginLeft: "5px",
                                        }}
                                      />
                                    }
                                    onClick={() => this.ViewData(arrList)}
                                  >
                                    View
                                  </Button> */}
                                  <Delete
                                    deleteID={arrList.fldi_id}
                                    table={"contact_us_enquiry"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.fldi_id,
                                        index
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                  >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.scrolling ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={6000}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>

        {/* Dialog Data start here */}
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          fullWidth={true}
          //   maxWidth="100%"
          open={this.state.viewDiallogData}
        >
          <DialogTitle id="form-dialog-title">
            <p style={{ fontSize: "0.8rem", textAlign: "justify" }}>
              {this.state.message}
            </p>
          </DialogTitle>

          <form
            id="courses"
            onSubmit={(e) => this.onFormSubmit(e, this.state.data)}
          >
            <DialogContent style={{ overflowY: "hidden" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Add a Reply...."
                    variant="outlined"
                    name="reply"
                    multiline
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.reply}
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="buttonCancel">
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => this.closeDialog(e)}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {" "}
                Submit{" "}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          onClose={this.handleReplyClose}
          aria-labelledby="simple-dialog-title"
          fullWidth={true}
          //   maxWidth="100%"
          open={this.state.viewReplyDiallogData}
        >
          <DialogTitle id="form-dialog-title">
            <p style={{ fontSize: "0.8rem", textAlign: "justify" }}>
              {this.state.reply}
            </p>
          </DialogTitle>
        </Dialog>
        {/* Dialog Data end here */}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Contact_us_enquiry);
