import React, { Component } from "react";  
import axios from "axios";
import { apiURL } from "../utils";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { withStyles} from "@material-ui/core/styles";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TextareaAutosize, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 

const useStyles = theme => ({
    button: {
      textTransform:'capitalize',
      border:'1px solid rgb(25 118 210)',
      borderRadius:'0px',
      padding:'2px 5px',
    },
  });

class Meta extends Component {
    _isMounted = false;
    constructor(props) {
      super(props);
      this.state = {
        editorVlaue: "",
        showDialog: false,
        page_title: "",
        meta_key: "",
        meta_name: "",
        meta_description: "",
        metaData: [],
      };
      
      this.changeHandler     = this.changeHandler.bind(this);
      this.getMeta           = this.getMeta.bind(this);
      this.onFormSubmit      = this.onFormSubmit.bind(this);
    }

    changeHandler = (e, type) => { 
      this.setState({ 
          [e.target.name]: e.target.value,
      }); 
      console.log(e.target.name +'===='+e.target.value);
    }

    componentDidMount(){
      //this.getMeta();
    }

    onFormSubmit = (e) => { 
      e.preventDefault();
      const headers = {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json'
      }
      const body = {
          "page_title": this.state.page_title,
          "meta_key": this.state.meta_key,
          "meta_name": this.state.meta_name,
          "meta_description": this.state.meta_description,
          "object_id": this.props.primaryId,
          "table": this.props.table,
          "use_for": this.props.useFor
      }
      axios.post(apiURL('meta/add'), body, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          { 
            this.dialogClose();
            this.setState({ 
              showSnackbar: true, 
              snackBarMessage:  resultData.message, 
              severityColor: resultData.status === true?'success':'error',
              snackBarTimer: 3000
          });
        }
        else{
            this.setState({ 
                showSnackbar: true, 
                snackBarMessage:  resultData.message,
                severityColor: 'error',
                snackBarTimer: 2000
            });
        }
      })
      .catch(error => {
          console.log(error)
      }) 
  }

  getMeta = () => {
      const headers = {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json'
      }
      const body = {
          "object_id": this.props.primaryId,
          "table": this.props.table,
          "use_for": this.props.useFor
      }
      axios.post(apiURL('meta/get_meta'), body, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          {  
            this.setState({ 
              page_title: resultData.result.fldv_title,
              meta_key: resultData.result.fldv_meta_key,
              meta_name: resultData.result.fldv_meta_name,
              meta_description: resultData.result.fldv_meta_description,
            });
            this.componentDidMount();
          } 
      })
      .catch(error => {
          console.log(error)
      }) 
  }

  dialogClose = (content, editor) => {
    this.setState({
      page_title: "",
      meta_key: "",
      meta_name: "",
      meta_description: "",
      showDialog: false
    });
  };

  showDialog = (e) => {
    this.getMeta();
    this.setState({showDialog: true})
  }

  
  snackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
      }
      this.setState({ showSnackbar: false }); 
  }

  render() {
    const { classes } = this.props; 
    
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          color="default"
          className={classes.button}
          startIcon={<BorderColorIcon style={{color:'rgb(25 118 210)'}} />}
          onClick={(e) => this.showDialog(e)}
          style={{marginLeft:"10px"}}
        >
          Create Meta
        </Button>
        <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'sm'}>
                <form  id="courses" onSubmit={(e) => this.onFormSubmit(e)} >
                    <DialogTitle id="form-dialog-title">
                        <h3 style={{textAlign:'center'}}>Add / Edit Meta </h3>
                    </DialogTitle>
                    <DialogContent style={{overflowY: 'hidden'}}>  
                        <Grid container spacing={3}> 
                            <Grid item xs={12} sm={12}>   
                                <TextField fullWidth type="text" label="Page Title" variant="outlined" name="page_title"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.page_title}  />
                            </Grid> 

                            <Grid item xs={12} sm={12}>   
                                <TextField fullWidth type="text" label="Meta Key" variant="outlined" name="meta_key"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.meta_key}  />
                            </Grid> 
                            
                            <Grid item xs={12} sm={12}>   
                                <TextField fullWidth type="text" label="Meta Name" variant="outlined" name="meta_name"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.meta_name}  />
                            </Grid> 
                            
                            <Grid item xs={12} sm={12}>   
                              <TextareaAutosize
                                rowsMin={7}
                                aria-label="maximum height"
                                placeholder="Meta description"
                                name="meta_description"
                                defaultValue={this.state.meta_description}
                                onChange={ e => this.changeHandler(e)}
                                style={{width:"100%"}}
                              />
                            </Grid> 
                              
                          </Grid>
                    </DialogContent>
                    <DialogActions className="buttonCancel">
                        <Button variant="contained" onClick={() => this.dialogClose()} color="secondary"> Cancel </Button>
                        <Button type="submit" variant="contained" color="primary"> Submit </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                { this.state.snackBarMessage }
                </Alert>
            </Snackbar>
      </React.Fragment>
    );
  }
}


export default  withStyles(useStyles)(Meta);


  