import React, { Component } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions,Button, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import { apiURL } from '../utils'; 
import md5 from 'md5';

const userData = JSON.parse(sessionStorage.getItem('userData'));
class ChangePassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            dialogOpn: false,
            showError: false,
            showSuccess: false,
            old_password: '',
            old_password_error: '',
            password: '',
            password_error: '',
            confirm_password: '',
            confirm_password_error: '',
        }
        
        this.handleDialogOpen   = this.handleDialogOpen.bind(this);
        this.handleDialogClose  = this.handleDialogClose.bind(this);
        this.submitHandler      = this.submitHandler.bind(this);
        this.changeHandler      = this.changeHandler.bind(this);
        

    }

    handleDialogOpen = () => {
        this.setState({
            dialogOpn: true
        })
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpn: false,
            old_password: "",
            password: "",
            confirm_password: "",
            old_password_error: "",
            password_error: "",
            confirm_password_error: "",
        }) 
    }; 

    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
         }); 
         console.log(e.target.name + '====='+e.target.value);
    }

    componentDidMount() {
      const userData = JSON.parse(sessionStorage.getItem('userData'));
    }

    submitHandler = (e) => {
        e.preventDefault();  
        console.log(userData); 
        if(this.state.old_password === ""){ 
          this.setState({
            old_password_error: "Please enter old password",
          })
          return false;
        }  
        if(!this.state.password){ 
          this.setState({
            password_error: "Please enter password"
          })
          return false;
        }  
        if(!this.state.confirm_password){ 
          this.setState({
            confirm_password_error: "Please enter confirm password"
          })
          return false;
        } 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'email':userData.fldv_email, 'password':this.state.password })
        };
        fetch(apiURL('login/change_password'), requestOptions)
            .then(async response => {
                const data = await response.json(); 
                if (data.status === false) { 
                    return this.setState({error: true});
                }  
                sessionStorage.setItem('userData', JSON.stringify(data.result));
                this.setState({ 
                    showSuccess: true,
                    old_password: "",
                    password: "",
                    confirm_password: "",
                }); 
                this.handleDialogClose(); 
            })
            .catch(error => { 
               this.setState({ showError: true, password: ""});
            });
        
      }

      checkValidation = (e, type) => {   
        if(this.state.old_password === ""){ 
          this.setState({
            old_password_error: "Please enter old password",
          })
          return false;
        } 
        else if(md5(this.state.old_password) !== userData.fldv_password)
        {
          this.setState({
            old_password_error: "old password not match", 
          })
          return false;
        }
        else{
          this.setState({
            old_password_error: ""
          })
        }    
        if(this.state.password === ""){ 
          this.setState({
            password_error: "Please enter password"
          })
          return false;
        } 
        else{
          this.setState({
            password_error: ""
          })
        }   
        if(this.state.confirm_password === ""){ 
          this.setState({
            confirm_password_error: "Please enter confirm password"
          })
          return false;
        }
        else if(this.state.confirm_password !== this.state.password)
        {
          this.setState({
            confirm_password_error: "password and confirm password shold be same", 
          })
          return false;
        }
        else{
          this.setState({
            confirm_password_error: ""
          })
        }  
      
      }

    render(){
        const { to, maxWidth, ...rest } = this.props;
        return (
            <React.Fragment>
                <Typography style={{padding:'2px 3px'}} color="inherit" noWrap onClick={() => this.handleDialogOpen()}>Change Password</Typography>

                <Dialog open={this.state.dialogOpn} onClose={() => this.handleDialogClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth={'xs'}>
                <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>  
                    <DialogContent component="main" maxWidth={'xs'}> 
                        <form className=""  id="change-password"  onSubmit={(e) => this.submitHandler(e)}>

                          <TextField variant="outlined" margin="normal" fullWidth label="Enter Old Password"  name="old_password" id="old_password" type="password" value={this.state.old_password} autoComplete="old_password" onChange={(e) => this.changeHandler(e)} value={this.state.old_password} helperText= {this.state.old_password_error} onBlur={(e) => this.checkValidation(e, 'old_password')} error={this.state.old_password_error !== ""?this.state.old_password_error:''}/> 

                         <TextField variant="outlined" margin="normal" fullWidth label="Enter New Password"  name="password" id="password" type="password" value={this.state.password} autoComplete="password" onChange={(e) => this.changeHandler(e)} value={this.state.password} helperText= {this.state.password_error} onBlur={(e) => this.checkValidation(e, 'password')} error={this.state.password_error !== ""?this.state.password_error:''}/> 
                          
                          <TextField variant="outlined" margin="normal" fullWidth label="Confirm Password"  name="confirm_password" id="confirm_password" type="password" value={this.state.confirm_password} autoComplete="confirm_password" onChange={(e) => this.changeHandler(e)} value={this.state.confirm_password} helperText= {this.state.confirm_password_error} onBlur={(e) => this.checkValidation(e, 'confirm_password')} error={this.state.confirm_password_error !== ""?this.state.confirm_password_error:''}/> 

                          <Button  type="submit" fullWidth  variant="contained" color="primary"> Reset Password </Button>
                        </form>
                         {this.state.showSuccess && <Alert severity="success">Password Changed Successfully !</Alert>}
                         {this.state.showError && <Alert severity="error">Something Went wrong! Please Try Again.</Alert>}
                    </DialogContent> 
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose()} color="primary"> Close </Button> 
                    </DialogActions> 
             </Dialog>
            </React.Fragment>
        );
    }
    
}

export default ChangePassword;