import React, { Component } from "react";  
import axios from "axios";
import { apiURL } from "../utils"; 
import { withStyles} from "@material-ui/core/styles";
import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import SendIcon from '@material-ui/icons/Send';

const useStyles = theme => ({
    button: {
      textTransform:'capitalize',
      border:'1px solid rgb(25 118 210)',
      borderRadius:'0px',
      padding:'2px 5px',
    },
  });

class PushNotification extends Component {

    constructor(props){
        super(props)
        this.state = {
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            snackBarTimer:'',
        }
        this.send_push_notification = this.send_push_notification.bind(this);
        this.snackBarClose          = this.snackBarClose.bind(this);
    }

    componentDidMount()
    {

    }

    send_push_notification = (e) => { 
        e.preventDefault();
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
        const body = {
            "title": this.props.title,
            "body": this.props.body.substring(0, 500),
            "id": this.props.id,
            "url": this.props.url, 
        }
        axios.post(apiURL('common/send_push_notification'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
           if(resultData.status === true || resultData.status === 1)
            {  
              this.setState({ 
                showSnackbar: true, 
                snackBarMessage: 'notification sent successfully', 
                severityColor: resultData.status === true?'success':'error',
                snackBarTimer: 6000
            });
          }
          else{
              this.setState({ 
                  showSnackbar: true, 
                  snackBarMessage: 'something went wtong! please try after sometime',
                  severityColor: 'error',
                  snackBarTimer: 5000
              });
          }
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
                    
                <Button variant="outlined" color="default" className={classes.button} startIcon={<SendIcon style={{color:'rgb(25 118 210)'}} />} style={{marginLeft:"10px"}} onClick={(e) => this.send_push_notification(e)}> Push Notification </Button>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>

            </React.Fragment>
        )
    }

}

export default  withStyles(useStyles)(PushNotification);