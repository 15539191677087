import React, { Component } from 'react';
import { apiURL } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, Select, MenuItem, InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';   
import Helmet from 'react-helmet';
import Delete from "../delete/Delete";
import ClearIcon from '@material-ui/icons/Clear';

import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";  
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';


const drawerWidth = 280;
// const useStyles = makeStyles => ({
    
// });
const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });
 class Speaker extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            showDialog: false,
            qualification: '',
            file: null,
            imagePreview: null,
            qualificationData: [],
            speakerData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false,
            imageEdit: false,
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            mode: '',
            searchValue:'',
            dimensions: {},
            noData: ''
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.speakerAddDialog       = this.speakerAddDialog.bind(this);
        this.speakerAddDialogClose  = this.speakerAddDialogClose.bind(this);
        this.changeHandler          = this.changeHandler.bind(this);
        this.onFormSubmit           = this.onFormSubmit.bind(this);
        this.onEditFormSubmit       = this.onEditFormSubmit.bind(this);
        this.speakerEditDialogOpen  = this.speakerEditDialogOpen.bind(this);
        this.handleImageChange      = this.handleImageChange.bind(this);
        this.onImgLoad              = this.onImgLoad.bind(this);
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }


    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
        }); 
        //console.log(e.target.name +'===='+e.target.value);
    }
    handleImageChange = (e) => {
        this.setState({  
            imagePreview: URL.createObjectURL(e.target.files[0]),
            file:e.target.files[0],
            imageEdit: true
         })
    }
 
    removeImage = (e) => {
        this.setState({  
            imagePreview: "",
            file:"",
            imageEdit: false,
            image: ""
         })
    }

    onImgLoad({target:img}) { 
        if(img.naturalHeight !== 180 && img.naturalWidth !== 180)
        { 
            this.setState({
                showSnackbar: true, 
                snackBarMessage:  'please provide image size as given',
                severityColor: 'error',
                snackBarTimer: 2000,
                file: null,
                image: "",
                imagePreview:''
            });
        } 
    }

    componentDidMount() { 
        this._isMounted = true; 
        this.qualificationList(); 
        this.speakerListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    qualificationList = () => {  
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.post(apiURL('qualification'), {headers: headers})
        .then(response => {  
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({ 
                    qualificationData: resultData.result, 
                }); 
            }
        } 
        })
        .catch(error => {
            console.log(error)
        })
    }

    speakerListData = () => {
        //this.setState({ loading: true }); 
        const { per, page, speakerData } = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "search": document.getElementById('search').value
        }
        axios.post(apiURL('speaker?per_page='+per+'&page='+page), body, {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    speakerData: (resultData.total < 20)?resultData.result:[...this.state.speakerData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false, scrolling: true });
            }
            else{
                this.setState({ loading: false, scrolling: false, noData: "No Speaker Found" }); 
            }
        }
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.speakerListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    onFormSubmit = (e) => { 
        e.preventDefault();
        //e.stopPropagation();  
        const formData  = new FormData();
        formData.append('file', this.state.file);
        formData.append('name', this.state.name);
        formData.append('email', this.state.email); 
        formData.append('contact', this.state.contact); 
        formData.append('qualification', this.state.qualification);  
        
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
        axios.post(apiURL('speaker/add'),formData, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.speakerAddDialogClose();
                this.componentDidMount();
                this.speakerListData();
                this.setState({ imageEdit: false, imagePreview: null, file: null  }); 
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                    showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
           
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    onEditFormSubmit = (e) => { 
         e.preventDefault(); 
         const editFormData  = new FormData();
         editFormData.append('id', this.state.id);
         editFormData.append('name', this.state.name);
         editFormData.append('email', this.state.email); 
         editFormData.append('contact', this.state.contact); 
         if(this.state.file !== null){
             editFormData.append('file', this.state.file);
         }
         editFormData.append('exist_file', this.state.image);
         editFormData.append('qualification', this.state.qualification); 
         editFormData.append('image_change', this.state.imageEdit);
         const headers = {
             'content-type': 'multipart/form-data',
             'Accept': 'application/json'
         }
         axios.post(apiURL('speaker/edit'),editFormData, {headers: headers})
         .then(response => {
             const resultData = response.data; 
             if(resultData.status === true || resultData.status === 1)
             {  
                 this.speakerAddDialogClose();
                this.componentDidMount();  
                this.speakerListData();
                 this.setState({ imageEdit: false, imagePreview: null, file: null });
               
                 this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message, 
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                    showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
            
         })
         .catch(error => {
             console.log(error)
         })
    }

    speakerAddDialog = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'add',
            file: '',
            name: '',
            email: '',
            contact: '',
            qualification: '',
            dimensions:{}, 
            image:""
        });
    }

    speakerEditDialogOpen = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'edit',
            id: e.fldi_id,
            image: e.fldv_image_url,
            name: e.fldv_name,
            email: e.fldv_email,
            contact: e.fldv_contact,
            qualification: e.fldi_qualification_id,
            dimensions:{}
        });  
    }

    speakerAddDialogClose = () => { 
        this.setState({ showDialog: false });
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    changeStatus = (id, status, isBest) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "id": id,
            "status":status.toString(),
            "is_best": isBest.toString()
        }
        axios.post(apiURL('speaker/change_status'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updateSpeakerData = this.state.speakerData.map(speker=>{
                    if(speker.fldi_id === id){
                        const _speker = {...speker};
                        if(status){
                            _speker.flg_status = status==="0"?"1":"0";
                         }  
                         if(isBest)  
                         {
                            _speker.flg_is_best = isBest==="0"?"1":"0";
                         }
                        return _speker;
                    }
                    return speker;
                });
                this.setState({ speakerData: updateSpeakerData });
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

      searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value}, () => { 
            this.speakerListData();  
          })
    }

    
    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteSpeaker = this.state.speakerData;
            deleteSpeaker.splice(index, 1);
            this.setState({
                speakerData: deleteSpeaker, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 
    }

    exportFile() {
        let headers = [["Name", "Email Address", "Phone Number", "Qualification", "Image", "Status", "Delete"]]
        this.state.speakerData.forEach((value) => {
          let valueArray = [value.fldv_name, value.fldv_email, value.fldv_contact, value.qualifaction_name, value.fldv_image_url,  (value.flg_status === '1')?"Active":"Deactive", (value.flg_is_deleted === '1')?"Not Delete":"Deleted"]
          headers.push(valueArray)
        })
    
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(headers)
        
        XLSX.utils.book_append_sheet(wb, wsAll, "Speaker")
            XLSX.writeFile(wb, "export-speakers.xlsx")
      }
   
    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
          const search = {  
            border: '1px solid',
            float: 'left',
            marginRight: '67px',
            background: 'rgba(255, 255, 255, 0.29)',
        }
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        const {width, height} = this.state.dimensions;
        return(
            <div>
                <Helmet>
                    <title>Speaker</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" >  
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div> 
                                    <TextField className="textField" type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    /> 

                                    <Button className={classes.buttonAdd} variant="contained" color="primary" startIcon={<AddCircleOutlineOutlinedIcon/>}   onClick={this.speakerAddDialog}> Add Speaker </Button>  
                                    <ExportExcelButton clickProp={(e) => this.exportFile(e)}/>
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Speaker </h2>
                        </Alert>
                        </Box>   
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead> 
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell> Name</TableCell>
                                                <TableCell>Email Address</TableCell>
                                                <TableCell>Phone Number </TableCell>
                                                <TableCell>Qualification</TableCell>
                                                <TableCell>Image</TableCell>
                                                <TableCell>Set to Home</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.speakerData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{arrList.fldv_name}</TableCell>
                                                    <TableCell>{arrList.fldv_email}</TableCell>
                                                    <TableCell>{arrList.fldv_contact}</TableCell>
                                                    <TableCell>{arrList.qualifaction_name}</TableCell>
                                                    <TableCell><img style={{width:'25px', marginLeft:'10px'}} src={arrList.fldv_image_url} alt={arrList.fldv_name} /></TableCell>
                                                    <TableCell> <Switch size="small" checked={arrList.flg_is_best==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id,'',arrList.flg_is_best)}/></TableCell>
                                                    <TableCell className="customSwitch"> <Switch size="small" checked={arrList.flg_status==="1"} color="secondary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status, '')}/></TableCell>
                                                    <TableCell>
                                                        <Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<EditIcon style={{color:'#035eab', fontSize:'1rem',}} />}  onClick={() => this.speakerEditDialogOpen(arrList)}>Edit</Button>
                                                        <Delete deleteID={arrList.fldi_id} table={'speaker'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/> 
                                                    </TableCell>
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table> 
                                    </TableContainer>
                                    </Box>
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{(this.state.scrolling)?this.state.noData:""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'sm'}>
                    <form  id="courses"  onSubmit={this.state.mode === 'edit'?(e) => this.onEditFormSubmit(e):(e) => this.onFormSubmit(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>Add Speaker</h3></DialogTitle>
                        <DialogContent>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Name" variant="outlined" name="name"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.name} />
                                </Grid> 
                                <Grid item xs={12} sm={12}>
                                    <TextField fullWidth type="text" label="Email" variant="outlined" name="email"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.email} />
                                </Grid> 
                                <Grid item xs={12} sm={12}>
                                    <TextField fullWidth type="text" label="Contact Number" variant="outlined" name="contact" size="small" onChange={ e => this.changeHandler(e)} value={this.state.contact} />
                                </Grid>  
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" size="small" style={{width:'100%'}}>
                                        <InputLabel>Qualification</InputLabel>
                                        <Select name="qualification" value={this.state.qualification} onChange={ e => this.changeHandler(e)} label="Qualification" >
                                            <MenuItem value=""> <em>None</em> </MenuItem>
                                            {this.state.qualificationData.map((arrQualification, index) => {
                                                return <MenuItem key={arrQualification.fldi_id} value={arrQualification.fldi_id}>{arrQualification.fldv_name}</MenuItem>
                                            })} 
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    {(this.state.imagePreview || this.state.image)?<ClearIcon  onClick={(e) => this.removeImage(e)} />: ""}
                                    {this.state.imageEdit === true?
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.imagePreview}/>:
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.image}/>
                                    }
                                    <Button variant="contained" startIcon={<ImageSearchIcon/>} color="primary" component="label">Profile Photo
                                        <input type="file" style={{ display: "none" }} onChange={this.handleImageChange} />
                                    </Button>
                                   
                                    {/* {this.state.imageEdit === true?<img style={{width:'40px'}} src={this.state.imagePreview}  onLoad={this.onImgLoad} />:<img style={{width:'40px'}} src={this.state.image} onLoad={this.onImgLoad} />} */}
                                    {/* Image width : 180px and Height: 180px */}
                                </Grid>  
                             </Grid>
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.speakerAddDialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Speaker);