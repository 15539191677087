import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SpeakerIcon from "@material-ui/icons/Speaker";
import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import CategoryIcon from "@material-ui/icons/Category";
import BookIcon from "@material-ui/icons/Book";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import LanguageIcon from "@material-ui/icons/Language";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import InfoIcon from "@material-ui/icons/Info";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import ForumIcon from "@material-ui/icons/Forum";
import DvrIcon from "@material-ui/icons/Dvr";
import "../include/Sidenav.css";
import registereduser from "../images/registereduser.png";
const useStyles = makeStyles((theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(3),
    },
  })
);

export default function Sidenav() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [userOpen, setUserOpen] = React.useState(false);
  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [resourcesOpen, setResourcesOpen] = React.useState(false);
  const [tsOpen, setTSOpen] = React.useState(false);
  const [webinarOpen, setWebinarOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleUserClick = () => {
    setUserOpen(!userOpen);
  };

  const aboutHandleClick = () => {
    setAboutOpen(!aboutOpen);
  };
  const resourcesHandleClick = () => {
    setResourcesOpen(!resourcesOpen);
  };

  const tsHandleClick = () => {
    setTSOpen(!tsOpen);
  };

  const handleWebinarClick = () => {
    setWebinarOpen(!webinarOpen);
  };

  return (
    <List className="sideNavBox">
      <ListItem button>
        <ListItemIcon>
          <Link to="/dashboard">
            {/* <DashboardIcon /> */}
            {/* <img src="images/dashboard.png"  alt=""/> */}
            <img src={process.env.PUBLIC_URL + "/images/dashboard.png"} />
            {/* <img src={window.location.origin + '/images/dashboard.png'} />  */}
          </Link>
        </ListItemIcon>
        <Link to="/dashboard">
          <ListItemText primary="Dashboard" />
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Link to="/user-count">
            {/* <DvrIcon /> */}
            <img src={registereduser} />
          </Link>
        </ListItemIcon>
        <Link to="/user-count">
          <ListItemText primary="User Information " />
        </Link>
      </ListItem>
      <ListItem button onClick={tsHandleClick}>
        <ListItemIcon>
          {/* <InfoIcon /> */}
          <Link to="/training-menu">
            <img
              src={process.env.PUBLIC_URL + "/images/training-schedule.png"}
            />
          </Link>
        </ListItemIcon>
        <Link to="/training-menu">
          <ListItemText primary="Training" />
        </Link>
      </ListItem>
      {/* <Collapse in={tsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/speaker">
                <SpeakerIcon />
              </Link>
            </ListItemIcon>
            <Link to="/speaker">
              <ListItemText primary="Speaker" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/training-schedule">
                <ScheduleIcon />
              </Link>
            </ListItemIcon>
            <Link to="/training-schedule">
              <ListItemText primary="Training Schedule" />
            </Link>
          </ListItem>
        </List>
      </Collapse> */}
      <ListItem button>
        <ListItemIcon>
          <Link to="/announcement-news">
            {/* <AnnouncementIcon /> */}

            <img src={process.env.PUBLIC_URL + "/images/news.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/announcement-news">
          <ListItemText primary="Govt Circulars/GR" />
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Link to="/article">
            {/* <BookIcon /> */}

            <img src={process.env.PUBLIC_URL + "/images/blog.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/article">
          <ListItemText primary="Articles" />
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Link to="/faq">
            {/* <LiveHelpIcon /> */}

            <img src={process.env.PUBLIC_URL + "/images/faq.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/faq">
          <ListItemText primary="FAQ's" />
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Link to="/testimonials ">
            {/* <DvrIcon /> */}
            <img src={process.env.PUBLIC_URL + "/images/testimonials.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/testimonials">
          <ListItemText primary="Testimonials " />
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Link to="/contact-us-enquiry">
            <img src={process.env.PUBLIC_URL + "/images/enquiry.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/contact-us-enquiry">
          <ListItemText primary="Contact Us" />
        </Link>
      </ListItem>
      {/* <Collapse in={userOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/get-in-touch">
                <TrackChangesIcon />
              </Link>
            </ListItemIcon>
            <Link to="/get-in-touch">
              <ListItemText primary="Footer Enquiry" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/contact-us-enquiry">
                <UnsubscribeIcon />
              </Link>
            </ListItemIcon>
            <Link to="/contact-us-enquiry">
              <ListItemText primary="Contact Us Enquiry" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/help-us-to-improve">
                <UnsubscribeIcon />
              </Link>
            </ListItemIcon>
            <Link to="/help-us-to-improve">
              <ListItemText primary="Help Us To Improve" />
            </Link>
          </ListItem>
        </List>
      </Collapse> */}
      <ListItem button onClick={aboutHandleClick}>
        <ListItemIcon>
          {/* <InfoIcon /> */}
          <img src={process.env.PUBLIC_URL + "/images/about-us.png"} />
        </ListItemIcon>
        <ListItemText primary="About Us" />
        {aboutOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/team">
                <GroupIcon />
              </Link>
            </ListItemIcon>
            <Link to="/team">
              <ListItemText primary="Our Team" />
            </Link>
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/other-activities">
                <CategoryIcon />
              </Link>
            </ListItemIcon>
            <Link to="/other-activities">
              <ListItemText primary="Other Activities" />
            </Link>
          </ListItem>
        </List>
      </Collapse>
      <ListItem button>
        <ListItemIcon>
          {/* <Link to="/teacher"> */}
          {/* <DeveloperBoardIcon /> */}
          <img src={process.env.PUBLIC_URL + "/images/teachers.png"} />
          {/* </Link> */}
        </ListItemIcon>
        <Link to="/tr-for-teacher">
          <ListItemText primary="Tr for Teacher" />
        </Link>
      </ListItem>

      {/* <ListItem button onClick={resourcesHandleClick}>
        <ListItemIcon>
        
          <img src={process.env.PUBLIC_URL + "/images/resources.png"} />
        </ListItemIcon>
        <ListItemText primary="Glimpses" />
        {resourcesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={resourcesOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/video">
                <VideoLibraryIcon />
              </Link>
            </ListItemIcon>
            <Link to="/video">
              <ListItemText primary="Video" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/video-category">
                <CategoryIcon />
              </Link>
            </ListItemIcon>
            <Link to="/video-category">
              <ListItemText primary="Video Category" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/article">
                <AssignmentIcon />
              </Link>
            </ListItemIcon>
            <Link to="/article">
              <ListItemText primary="Article" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/article-category">
                <CategoryIcon />
              </Link>
            </ListItemIcon>
            <Link to="/article-category">
              <ListItemText primary="Article Category" />
            </Link>
          </ListItem>
        </List>
      </Collapse> */}

      {/*<ListItem button>
      <ListItemIcon>
      <Link to='/article'><AssignmentIcon /></Link>
      </ListItemIcon>
      <Link to='/article'><ListItemText primary="Article" /></Link>
    </ListItem>

    <ListItem button>
      <ListItemIcon>
      <Link to='/video'><VideoLibraryIcon /></Link> 
      </ListItemIcon>
      <Link to='/video'><ListItemText primary="Video" /></Link>
    </ListItem>*/}

      {/*<ListItem button>
      <ListItemIcon>
      <Link to='/training-schedule'><ScheduleIcon /></Link>
      </ListItemIcon>
      <Link to='/training-schedule'><ListItemText primary="Training Schedule" /></Link>
    </ListItem>*/}

      {/* <ListItem button onClick={handleClick}>
        <ListItemIcon>
       

          <img src={process.env.PUBLIC_URL + "/images/discussion-board.png"} />
        </ListItemIcon>
        <ListItemText primary="Discussion Board" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/category">
                <CategoryIcon />
              </Link>
            </ListItemIcon>
            <Link to="/category">
              <ListItemText primary="Categories" />
            </Link>
          </ListItem>

          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/forum">
                <ForumIcon />
              </Link>
            </ListItemIcon>
            <Link to="/forum">
              <ListItemText primary="Forum" />
            </Link>
          </ListItem>
        </List>
      </Collapse> */}

      <ListItem button>
        <ListItemIcon>
          <Link to="/menu">
            {/* <DashboardIcon /> */}

            <img src={process.env.PUBLIC_URL + "/images/menu.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/menu">
          <ListItemText primary="Menu" />
        </Link>
      </ListItem>

      {/* <ListItem button onClick={handleWebinarClick}>
        <ListItemIcon>
         
          <img src={process.env.PUBLIC_URL + "/images/webinar.png"} />
        </ListItemIcon>
        <ListItemText primary="Webinar" />
        {userOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={webinarOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <Link to="/webinar">
                <LanguageIcon />
              </Link>
            </ListItemIcon>
            <Link to="/webinar">
              <ListItemText primary="Webinar" />
            </Link>
          </ListItem>
        
        </List>
      </Collapse> */}

      {/* <ListItem button>
        <ListItemIcon>
          <Link to="/subscriber">
         
            <img src={process.env.PUBLIC_URL + "/images/subscribers.png"} />
          </Link>
        </ListItemIcon>
        <Link to="/subscriber">
          <ListItemText primary="Subscriber" />
        </Link>
      </ListItem> */}

      {/* 
      <ListItem button>
        <ListItemIcon>
          <Link to="/school-college">
            <LanguageIcon />
          </Link>
        </ListItemIcon>
        <Link to="/school-college">
          <ListItemText primary="School/College" />
        </Link>
      </ListItem> */}
    </List>
  );
}
