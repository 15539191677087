import React, { Component } from "react";
import clsx from "clsx";
import Header from "../include/Header";
//import Navigation from'./Navigation';
//import Footer from './Footer';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Paper, CssBaseline } from "@material-ui/core";
import Helmet from "react-helmet";

import Orders from "./Orders";
import Usermenu from "./Usermenu";
import Statistics from "./Statistics";
import WeeklyStats from "./WeeklyStats";
import TrainingGr from "./TrainingGr";
import LiveArticles from "./LiveArticles";
import TopFiveTraining from "./TopFiveTraining";
import Top5Articles from "./Top5Articles";
//import "../dashboard/Dashboard.css";
import TopFiveGR from "./TopFiveGR";
const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginTop: 5,
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    zIndex: 1,
    padding: theme.spacing(3),
  },
}));

class Dashboard extends Component {
  userStatus = JSON.parse(localStorage.getItem("userStatus"));
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      open: true,
      //drawerOpen: false,
      drawerOpen: true,
      per: 3,
      page: 1,
      total_pages: null,
    };
  }
  // React Life Cycle
  /*componentDidMount() {
        console.log(this.userStatus)
        if(!this.userStatus){
            alert('Please login first');
            this.props.history.push('/sign-in');
        }
    }*/
  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        {/*<Navigation /> */}
        <CssBaseline />
        <div
          style={{
            paddingTop: "6rem",
            paddingBottom: "8rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
            //display: 'inherit',
          }}
        >
          <main>
            <Statistics classes={classes} />
            <WeeklyStats classes={classes} />
            <TrainingGr />
            <LiveArticles />
            <TopFiveTraining />
            <Top5Articles />
            <TopFiveGR />
          </main>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Dashboard);

// <main className={classes.content} >
// <div className={classes.appBarSpacer} />
// <Container maxWidth="lg" className={classes.container}>
//   <Grid container spacing={3}  align="center" >
//     <Grid item xs={12}>
//       <Paper className={classes.paper}>
//         <Usermenu/>
//       </Paper>
//     </Grid>
//     </Grid>

// </Container>
// </main>
