import React, { Component } from "react";
import { apiURL } from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import Helmet from "react-helmet";
import Delete from "../delete/Delete";

import EditIcon from "@material-ui/icons/Edit";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import "../style/Style.css";
import Meta from "../meta/Meta";
import XLSX from "xlsx";
import ExportExcelButton from "../utils/ExportExcelButton";
import Moment from "moment";
const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = (theme) => ({
  buttonEdit: {
    textTransform: "capitalize",
    border: "1px solid #035eab",
    borderRadius: "0px",
    marginRight: "10px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 650,
  },
});

class Video extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      // drawerOpen: false,
      drawerOpen: true,
      showDialog: false,
      videoData: [],
      page: 1,
      per: 20,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      mode: "",
      videoCategory: [],
      category: "",
      noData: "",
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.addDialog = this.addDialog.bind(this);
    this.dialogClose = this.dialogClose.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.editDialogOpen = this.editDialogOpen.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  changeHandler = (e, type) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.name +'===='+e.target.value);
  };

  handleImageChange = (e) => {
    this.setState({
      imagePreview: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
      imageEdit: true,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.videoListData();
    this.videoCategory();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  videoCategory = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("video_category/get_all_video_category"), {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              videoCategory: resultData.result,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  videoListData = () => {
    this.setState({ loading: true });
    const { per, page, videoData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      search: document.getElementById("search").value,
    };
    axios
      .post(apiURL("video?per_page=" + per + "&page=" + page), body, {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              videoData:
                resultData.total < 20
                  ? resultData.result
                  : [...this.state.vdeoData, ...resultData.result],
              scrolling: false,
              total_pages: resultData.total_pages,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({
              loading: false,
              scrolling: false,
              noData: "No Video Found",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: true,
        }),
        this.videoListData
      );
    }
  };

  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector("Table");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = JSON.stringify({
      title: this.state.title,
      video: this.state.video,
      category: this.state.category,
      language: this.state.language,
    });
    axios
      .post(apiURL("video/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.dialogClose();
          this.componentDidMount();
          this.setState({ imageEdit: false, imagePreview: null, file: null });
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onEditFormSubmit = (e) => {
    e.preventDefault();
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    const body = JSON.stringify({
      title: this.state.title,
      video: this.state.video,
      id: this.state.id,
      category: this.state.category,
      language: this.state.language,
    });
    axios
      .post(apiURL("video/edit"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ imageEdit: false, imagePreview: null, file: null });
          this.dialogClose();
          this.componentDidMount();
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeStatus = (id, status) => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("video/change_status/" + id + "/" + status), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedVideo = this.state.videoData.map((video) => {
            if (video.fldi_id === id) {
              const _video = { ...video };
              _video.flg_status = status === "0" ? "1" : "0";
              return _video;
            }
            return video;
          });
          this.setState({ videoData: updatedVideo });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addDialog = (e) => {
    this.setState({
      showDialog: true,
      mode: "add",
      title: "",
      video: "",
      category: "",
      language: "",
    });
  };

  editDialogOpen = (e) => {
    this.setState({
      showDialog: true,
      mode: "edit",
      id: e.fldi_id,
      video: e.fldv_video,
      title: e.fldv_title,
      category: e.fldi_category_id,
    });
  };

  dialogClose = () => {
    this.setState({ showDialog: false });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  searchHandler = (e) => {
    this.setState({ page: 1, searchValue: e.target.value });
    this.videoListData();
  };

  successDelete = (e, id, index) => {
    if (e === true) {
      const deleteVideo = this.state.videoData;
      deleteVideo.splice(index, 1);
      this.setState({
        videoData: deleteVideo,
        showSnackbar: true,
        snackBarMessage: "data deleted suceesfully",
        severityColor: "success",
        snackBarTimer: 3000,
      });
    }
  };

  exportFile() {
    let headers = [
      ["Title", "Category", "Image", "Video Link", "Status", "Delete"],
    ];
    this.state.videoData.forEach((value) => {
      let valueArray = [
        value.fldv_title,
        value.fldv_category,
        value.fldv_image_capture,
        value.fldv_video,
        value.flg_status === "1" ? "Active" : "Deactive",
        value.flg_is_deleted === "1" ? "Not Delete" : "Deleted",
      ];
      headers.push(valueArray);
    });

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.book_append_sheet(wb, wsAll, "Video");
    XLSX.writeFile(wb, "export-video.xlsx");
  }

  render() {
    const { classes } = this.props;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div>
        <Helmet>
          <title>Video</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      <TextField
                        className="textField"
                        type="text"
                        placeholder="...Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          style: {
                            color: "#414141",
                            backgroundColor: "rgba(255, 255, 255, 0.29)",
                          },
                        }}
                        size="small"
                        id="search"
                        variant="outlined"
                        style={{ fontColor: "#fff", marginRight: "1rem" }}
                        name="search"
                        onChange={(e) => this.searchHandler(e)}
                      />
                      <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={this.addDialog}
                      >
                        {" "}
                        Add Video{" "}
                      </Button>
                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      />
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>Video </h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell> Title</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Uploaded Date & Time</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Average Rating</TableCell>
                            <TableCell>No of Views</TableCell>

                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.videoData.map((arrList, index) => {
                            return (
                              <TableRow key={arrList.fldi_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{arrList.fldv_title}</TableCell>
                                <TableCell>
                                  <img
                                    style={{
                                      width: "25px",
                                      marginLeft: "10px",
                                    }}
                                    src={arrList.fldv_image_capture}
                                    alt={arrList.fldv_title}
                                  />
                                </TableCell>
                                <TableCell>{arrList.fldv_category}</TableCell>
                                <TableCell>
                                  {Moment(arrList.flddt_created_date).format(
                                    "D MMM, YYYY, LT"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Switch
                                    size="small"
                                    checked={arrList.flg_status === "1"}
                                    color="primary"
                                    onChange={() =>
                                      this.changeStatus(
                                        arrList.fldi_id,
                                        arrList.flg_status
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {Number(arrList.average_rating).toFixed(1)}
                                </TableCell>
                                <TableCell>{arrList.no_of_clicks}</TableCell>
                                <TableCell>
                                  <Button
                                    className={classes.buttonEdit}
                                    variant="outlined"
                                    color="default"
                                    onClick={() => this.editDialogOpen(arrList)}
                                    startIcon={
                                      <EditIcon
                                        style={{
                                          color: "#035eab",
                                          fontSize: "1rem",
                                        }}
                                      />
                                    }
                                  >
                                    Edit
                                  </Button>
                                  <Delete
                                    deleteID={arrList.fldi_id}
                                    table={"video"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.fldi_id,
                                        index
                                      )
                                    }
                                  />
                                  <Meta
                                    primaryId={arrList.fldi_id}
                                    useFor={"inner_page"}
                                    table={"video"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.fldi_id,
                                        index
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                  >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.scrolling ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>

        <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={"sm"}>
          <form
            id="courses"
            ref={(c) => {
              this.form = c;
            }}
            onSubmit={
              this.state.mode === "edit"
                ? (e) => this.onEditFormSubmit(e)
                : (e) => this.onFormSubmit(e)
            }
          >
            <DialogTitle id="form-dialog-title">
              <h3 style={{ textAlign: "center" }}>
                {" "}
                {this.state.mode === "add" ? "Add" : "Edit"} Video{" "}
              </h3>
            </DialogTitle>
            <DialogContent style={{ overflowY: "hidden" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={this.state.category}
                      onChange={(e) => this.changeHandler(e)}
                      label="Speaker"
                      required
                    >
                      <MenuItem value="">
                        {" "}
                        <em>None</em>{" "}
                      </MenuItem>
                      {this.state.videoCategory.map((arrCategory, index) => {
                        return (
                          <MenuItem
                            key={arrCategory.fldi_id}
                            value={arrCategory.fldi_id}
                          >
                            {arrCategory.fldv_category}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Title"
                    variant="outlined"
                    name="title"
                    size="small"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.title}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Video Url"
                    variant="outlined"
                    name="video"
                    size="small"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.video}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <InputLabel>Language</InputLabel>
                    <Select
                      name="language"
                      value={this.state.language}
                      onChange={(e) => this.changeHandler(e)}
                      label="Language"
                      required
                    >
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Marathi">Marathi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="buttonCancel">
              <Button
                variant="contained"
                onClick={() => this.dialogClose()}
                color="secondary"
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {" "}
                Submit{" "}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(useStyles)(Video);
