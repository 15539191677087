import React, { Component } from 'react';
import { apiURL, sanitizeHtml } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { makeStyles, withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, InputAdornment, TextField, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import axios from 'axios';
import Helmet from 'react-helmet';
import SearchIcon from '@material-ui/icons/Search';
import Delete from "../delete/Delete";

import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box';
import "../style/Style.css";  
import Meta from "../meta/Meta";

const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });

 class Forum_comments extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            joinWebinarUserData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false, 
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            noData: ''
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.changeStatus           = this.changeStatus.bind(this)
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }

    componentDidMount() { 
        this._isMounted = true; 
        this.joinWebinarListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    joinWebinarListData = () => {
        this.setState({ loading: true });
        const { per, page, contactData } = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "forum_id": this.props.match.params.forumId,
            "search": document.getElementById('search').value
        }
        axios.post(apiURL('forum_comments?per_page='+per+'&page='+page), body,  {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    joinWebinarUserData: (resultData.total < 20)?resultData.result:[...this.state.joinWebinarUserData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false });
            }
            else{
                this.setState({ loading: false, noData:"No User Found" }); 
            }
        }
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.articleListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    this.loadMore();
                } 
            }
        }
      };


    changeStatus = (id, status) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "id": id,
            "status": status
        }
        axios.post(apiURL('forum_comments/change_status'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updatedJoinWebinarUserDataData = this.state.joinWebinarUserData.map(jwu=>{
                    if(jwu.fldi_id === id){
                        const _jwu = {...jwu};
                        _jwu.flg_status = status==="0"?"1":"0";
                        return _jwu;
                    }
                    return jwu;
                });
                this.setState({ joinWebinarUserData: updatedJoinWebinarUserDataData });
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

    searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value}, () => { 
            this.joinWebinarListData();  
          })
       // this.joinWebinarListData();
    }
    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteData = this.state.joinWebinarUserData;
            deleteData.splice(index, 1);
            this.setState({
                joinWebinarUserData: deleteData, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 
    }

    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return(
            <React.Fragment>
            <Helmet>
                <title>Forum Cooments</title>
            </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" >   
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div>
                                    <TextField className="textField" type="text" placeholder="...Search"  InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}    
                                        }} 
                                    size="small" id="search" variant="outlined"  style={{fontColor:"#fff"}} name="search" onChange={e => this.searchHandler(e)} />
                                    {/*<Button color="inherit" size="small" onClick={this.addDialog}> Add Category </Button> */} 
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Forum Comments </h2>
                        </Alert>
                        </Box>
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead> 
                                            <TableCell>Id</TableCell>
                                            <TableCell> Comments </TableCell>  
                                            <TableCell> Name</TableCell> 
                                            <TableCell> Email</TableCell> 
                                            <TableCell> Contact</TableCell> 
                                            <TableCell>Status</TableCell> 
                                            <TableCell>Action</TableCell>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.joinWebinarUserData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell> 
                                                    <TableCell>{sanitizeHtml(arrList.fldt_content)}</TableCell>
                                                    <TableCell>{arrList.fldv_name}</TableCell>
                                                    <TableCell>{arrList.fldv_email}</TableCell>
                                                    <TableCell>{arrList.fldv_contact}</TableCell>
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell>  
                                                        <Delete deleteID={arrList.fldi_id} table={'forum_answer'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>

                                                        <Meta primaryId={arrList.fldi_id} useFor={"inner_page"} table={'forum_answer'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                    </TableCell>
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                    </Box> 
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{(this.state.scrolling)?this.state.noData:""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={6000} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </React.Fragment>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Forum_comments);