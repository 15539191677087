import React, { Component } from 'react';
import { apiURL } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import axios from 'axios'; 
import SearchIcon from '@material-ui/icons/Search'; 
import Helmet from 'react-helmet';
import Delete from "../delete/Delete"; 

import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box'; 
import "../style/Style.css";   
import Meta from "../meta/Meta";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"




const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))

const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
     //console.log(result)
    return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
})


 class Menu extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            showDialog: false, 
            menuData: [],
            page: 1,
            per: 20,
            total_pages: null,
            loading: false, 
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            mode: '',  
            editorVlaue: '',  
            snackBarTimer: '', 
            searchValue:'',
            noData: '',
            delete:false, 
            menuCreate: [],
            main_menu:'',
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.faqAddDialog          = this.faqAddDialog.bind(this);
        this.faqDialogClose        = this.faqDialogClose.bind(this);
        this.changeHandler          = this.changeHandler.bind(this);
        this.onFormSubmit           = this.onFormSubmit.bind(this);
        this.onEditFormSubmit       = this.onEditFormSubmit.bind(this);
        this.editDialogOpen         = this.editDialogOpen.bind(this);
        this.handleEditorChange     = this.handleEditorChange.bind(this); 
        this.changeStatus           = this.changeStatus.bind(this)
        this.createClildMenu        = this.createClildMenu.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        console.log(`dragEnd ${result.source.index+1} to  ${result.destination.index+1}`)
        //console.log('source '+JSON.stringify(result));
        //console.log('destination '+JSON.stringify(result));
        
        console.log(result);
        const source = result.source.index+1;
        const destination = result.destination.index+1;
        this.updateMenuPosition(result,source, destination);
        
        const items = reorder(
            this.state.menuData,
            result.source.index,
            result.destination.index
        )

        this.setState({
            menuData:items
        }) 
    }

    updateMenuPosition(positionData, source, destination)
    {
            const headers = { 
                'Content-type': 'application/json'
            }
            const body = {
                    "data": positionData,
                    "source" : source,
                    "destination" : destination,
                }
            axios.post(apiURL('menu/update_poition'), body, {headers: headers})
            .then(response => {
                
            if(this._isMounted){
                const resultData = response.data; 
                if(resultData.status === true || resultData.status === 1)
                { 

                } 
            }
            }) 
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }


    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
        });  
    }
    handleImageChange = (e) => {
        this.setState({  
            imagePreview: URL.createObjectURL(e.target.files[0]),
            file:e.target.files[0],
            imageEdit: true
         })
    }

    componentDidMount() { 
        this._isMounted = true; 
        this.menuListData();
        this.createClildMenu();
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }

    
    createClildMenu = () => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('menu/get_menu_create'), {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    menuCreate: resultData.result, 
                }); 
            } 
        }
        }) 
    }
 
  
    menuListData = () => {
        this.setState({ loading: true });
        const { per, page, menuData} = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "search": document.getElementById('search').value,
           //"search": this.state.searchValue,
        } 
        axios.post(apiURL('menu?per_page='+per+'&page='+page), body, {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    menuData: (resultData.total <= 20)?resultData.result:[...menuData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false,  scrolling: true, });
            }
            else{
                this.setState({ loading: false,  scrolling: false,noData: "No FAQ Found" }); 
            }
        }
        })
    
        .catch(error => {
            console.log(error)
        }) 
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState( prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.menuListData()
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                //console.log(lastLiOffset+' ===== ' +pageOffset)
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    onFormSubmit = (e) => { 
        e.preventDefault();
        //e.stopPropagation();   
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }   
        const body = {
            "name": this.state.name,
            "sub_title": this.state.sub_title,
        }
        axios.post(apiURL('menu/add'),body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.faqDialogClose();
                this.componentDidMount(); 
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
            }
            else{
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: 'error',
                    snackBarTimer: 2000
                });
            }
           
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    onEditFormSubmit = (e) => {
         e.preventDefault(); 
         const headers = {
             'content-type': 'multipart/form-data',
             'Accept': 'application/json'
         }
         
        const body = {
            "name": this.state.name,
            "sub_title": this.state.sub_title,
            'id': this.state.id,
            'main_menu': this.state.main_menu
        }
         axios.post(apiURL('menu/edit'),body, {headers: headers})
         .then(response => {
             const resultData = response.data; 
             if(resultData.status === true || resultData.status === 1)
             { 
                 this.faqDialogClose();
                 this.componentDidMount(); 
                 this.menuListData(); 
                 this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message, 
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
            else{
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: 'error',
                    snackBarTimer: 2000
                });
            }
         })
         .catch(error => {
             console.log(error)
         })
    }

    changeStatus = (id, status) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('menu/change_status/'+id+'/'+status), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updatedMenu = this.state.menuData.map(menu=>{
                    if(menu.fldi_id === id){
                        const _menu = {...menu};
                        _menu.flg_status = status==="0"?"1":"0";
                        return _menu;
                    }
                    return menu;
                });
                this.setState({ menuData: updatedMenu });
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

    faqAddDialog = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'add', 
            name: '', 
            sub_title: '',  
        });
    }

    editDialogOpen = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'edit',
            id: e.fldi_id, 
            name: e.fldv_name, 
            sub_title: e.fldv_sub_title,
            main_menu: (e.fldi_parent_id > 0)?e.fldi_parent_id:''
        });  
    }

    faqDialogClose = () => { 
        this.setState({ showDialog: false });
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    handleEditorChange = (content, editor) => { 
        this.setState({
            editorVlaue: content
        });
      }
 
    searchHandler = (e) => {
        this.setState({
            page: 1,
            searchValue:e.target.value
        }, () => { 
            this.menuListData();  
          });
       // this.menuListData();
    }

    successDelete = (e, id, index) => {  
        if(e === true){
            var deleteFaq = this.state.menuData;
            deleteFaq.splice(index, 1);
            this.setState({
                menuData: deleteFaq, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 

    }
   
    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return(
            <div>
                <Helmet>
                    <title>Menu</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" > 
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div>
                                    <TextField className="textField" type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff"}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    />
                                    {/*<Button color="inherit" size="small" onClick={this.faqAddDialog}> Add Menu </Button>  */}
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Menu </h2>
                        </Alert>
                        </Box>
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead> 
                                            <TableCell style={{width:'2rem'}}>Id</TableCell>  
                                            <TableCell> Name</TableCell> 
                                            <TableCell>Sub Title</TableCell> 
                                            <TableCell style={{width:'3rem'}}>Status</TableCell>
                                            <TableCell style={{width:'3rem'}}>Action</TableCell>
                                        </TableHead>
                                        <TableBody  component={DroppableComponent((e) => this.onDragEnd(e))}> 
                                            {this.state.menuData.map((arrList, index) => {    
                                             return <TableRow component={DraggableComponent(arrList.fldi_id, index)} key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell>  
                                                    <TableCell>{arrList.fldv_name}</TableCell> 
                                                    <TableCell>{arrList.fldv_sub_title}</TableCell>
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell> 
                                                        <Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<EditIcon style={{color:'#035eab', fontSize:'1rem',}} />} onClick={() => this.editDialogOpen(arrList)}>Edit</Button>
                                                       
                                                        <Delete deleteID={arrList.fldi_id} table={'menu'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                        <Meta primaryId={arrList.fldi_id} useFor={"main_page"} table={'menu'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                    </TableCell>
                                                     
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table>   
                                    </TableContainer>
                                    </Box>   
                              
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        {/*<span>{(this.state.scrolling)?this.state.noData:""}</span>*/}
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'sm'} disableEnforceFocus >
                    <form  id="courses"  onSubmit={this.state.mode === 'edit'?(e) => this.onEditFormSubmit(e):(e) => this.onFormSubmit(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>{(this.state.mode === 'add')?'Add':'Edit'} Menu</h3></DialogTitle>
                        <DialogContent style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}> 
                            <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" size="small" style={{width:'100%'}}>
                                        <InputLabel>Choose Main Menu</InputLabel>
                                        <Select name="main_menu" value={this.state.main_menu} onChange={ e => this.changeHandler(e)} label="Choose Main Menu">
                                            <MenuItem value=""> <em>None</em> </MenuItem>
                                            {this.state.menuCreate.map((arrMenu, index) => {
                                                return <MenuItem key={arrMenu.fldi_id} value={arrMenu.fldi_id}>{arrMenu.fldv_name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Name" variant="outlined" name="name"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.name} />
                                </Grid> 

                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Sub Title" variant="outlined" name="sub_title"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.sub_title} />
                                </Grid> 
                                  
                             </Grid>
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.faqDialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }

 
const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
}

const DroppableComponent = (
    onDragEnd: (result, provided) => void) => (props) =>
{
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}
  
 export default (withStyles)(useStyles)(Menu);