import React, { Component } from 'react';
import { apiURL } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { makeStyles, withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import axios from 'axios'; 
import SearchIcon from '@material-ui/icons/Search';
import Helmet from 'react-helmet';
import Delete from "../delete/Delete";

import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { spacing } from '@material-ui/system';  
import Box from '@material-ui/core/Box';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";    
import Meta from "../meta/Meta";
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';


const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({

// }))
 
const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });

 class Video_category extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            showDialog: false, 
            categoryData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false, 
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            mode: '',   
            noData: ''
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.addDialog              = this.addDialog.bind(this);
        this.dialogClose            = this.dialogClose.bind(this);
        this.changeHandler          = this.changeHandler.bind(this);
        this.onFormSubmit           = this.onFormSubmit.bind(this);
        this.onEditFormSubmit       = this.onEditFormSubmit.bind(this);
        this.editDialogOpen         = this.editDialogOpen.bind(this); 
        this.changeStatus           = this.changeStatus.bind(this)
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }


    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
        }); 
        //console.log(e.target.name +'===='+e.target.value);
    }
  
    componentDidMount() { 
        this._isMounted = true; 
        this.categoryListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    categoryListData = () => {
        this.setState({ loading: true });
        const { per, page, categoryData } = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "search": document.getElementById('search').value
        }
        axios.post(apiURL('video_category?per_page='+per+'&page='+page), body, {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    categoryData: (resultData.total < 20)?resultData.result:[...this.state.vdeoData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false, scrolling: true });
            }
            else{
                this.setState({ loading: false, scrolling: false, noData: "No Video Category Found" }); 
            }
        }
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.categoryListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    onFormSubmit = (e) => { 
        e.preventDefault();
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json',
        }
        const body = JSON.stringify({
            'category':this.state.category, 
        })
        axios.post(apiURL('video_category/add'),body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.dialogClose();
                this.componentDidMount(); 
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                    showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
            
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    onEditFormSubmit = (e) => {
         e.preventDefault(); 
         const headers = {
             'content-type': 'multipart/form-data',
             'Accept': 'application/json'
         }
         const body = JSON.stringify({
            'category':this.state.category,
            'id': this.state.id,
        })
         axios.post(apiURL('video_category/edit'),body, {headers: headers})
         .then(response => {
             const resultData = response.data; 
             if(resultData.status === true || resultData.status === 1)
             { 
                 this.dialogClose();
                 this.componentDidMount();  
                 this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                    showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
         })
         .catch(error => {
             console.log(error)
         })
    }

    changeStatus = (id, status) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('video_category/change_status/'+id+'/'+status), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updatedCategory = this.state.categoryData.map(category=>{
                    if(category.fldi_id === id){
                        const _category = {...category};
                        _category.flg_status = status==="0"?"1":"0";
                        return _category;
                    }
                    return  category;
                });
                this.setState({ categoryData: updatedCategory });
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

    addDialog = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'add', 
            category: '',
        });
    }

    editDialogOpen = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'edit',
            id: e.fldi_id,
            category: e.fldv_category, 
        });  
    }

    dialogClose = () => { 
        this.setState({ showDialog: false });
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }
   
    searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value})
        this.categoryListData();
    }
    
    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteCategory = this.state.categoryData;
            deleteCategory.splice(index, 1);
            this.setState({
                categoryData: deleteCategory, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 
    }

    exportFile() {
        let headers = [["Category", "Status", "Delete"]]
        this.state.categoryData.forEach((value) => {
          let valueArray = [value.fldv_category,  (value.flg_status === '1')?"Active":"Deactive", (value.flg_is_deleted === '1')?"Not Delete":"Deleted"]
          headers.push(valueArray)
        })
    
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(headers)
        
        XLSX.utils.book_append_sheet(wb, wsAll, "Video Category")
            XLSX.writeFile(wb, "export-video-category.xlsx")
      }

    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" }; 
        return(
            <div>
                <Helmet>
                    <title>Category</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" > 
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div>
                                    <TextField className="textField" type="text" placeholder="...Search"  InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                    size="small" id="search" variant="outlined"  style={{fontColor:'#fff', marginRight:'1rem'}} name="search" onChange={e => this.searchHandler(e)} />
                                    <Button className={classes.buttonAdd} variant="contained" color="primary" startIcon={<AddCircleOutlineOutlinedIcon/>}  onClick={this.addDialog}> Add Video Category </Button> 
                                    <ExportExcelButton clickProp={(e) => this.exportFile(e)}/> 
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Video Category </h2>
                        </Alert>
                        </Box>
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead> 
                                            <TableRow>
                                                <TableCell style={{width:'2rem'}}>Id</TableCell> 
                                                <TableCell>Category</TableCell>  
                                                <TableCell style={{width:'3rem'}}>Status</TableCell>
                                                <TableCell style={{width:'3rem'}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.categoryData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell> 
                                                    <TableCell>{arrList.fldv_category}</TableCell> 
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell>
                                                        <Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<EditIcon style={{color:'#035eab', fontSize:'1rem',}} />} onClick={() => this.editDialogOpen(arrList)}>Edit</Button>
                                                        <Delete deleteID={arrList.fldi_id} table={'video_category'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                        {/* <Meta primaryId={arrList.fldi_id} useFor={"inner_page"} table={'video_category'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>*/}
                                                    </TableCell>
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table> 
                                    </TableContainer>  
                                  </Box>
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{(this.state.scrolling)?this.state.noData:""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'sm'}>
                    <form  id="courses" ref={c => { this.form = c }} onSubmit={this.state.mode === 'edit'?(e) => this.onEditFormSubmit(e):(e) => this.onFormSubmit(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>{(this.state.mode === 'add')?'Add':'Edit'} Category </h3></DialogTitle>
                        <DialogContent style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Category" variant="outlined" name="category"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.category}  />
                                </Grid> 
                            </Grid> 
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.dialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Video_category);