import { Card, Container, Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import upcomingIcon from "../images/upcomingicon.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import training from "../images/trainingicon.png";
import webinar from "../images/pastwebicon.png";
import videos from "../images/videoicon.png";
import MhBoard from "../images/mh-boardicon.png";
import CentralBoard from "../images/centralicon.png";
import axios from "axios";
import { apiURL } from "../utils";

const TrainingGr = () => {
  const [trainingCounts, setTrainingCounts] = useState({
    upcoming: 0,
    pastWeb: 0,
    pastTrain: 0,
    videos: 0,
  });
  const [grCounts, setGrCount] = useState({
    mhBoard: 0,
    central: 0,
  });

  useEffect(() => {
    fetchCount();
  }, []);

  const fetchCount = (language) => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .get(apiURL("Training_dashboard/count_training"), { headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          console.log(resultData.result);

          // Converting string values to numbers
          setTrainingCounts({
            upcoming: Number(resultData.result.upcoming_events),
            pastWeb: Number(resultData.result.past_webinar),
            pastTrain: Number(resultData.result.past_training),
            videos: Number(resultData.result.educational_videos),
          });

          setGrCount({
            mhBoard: Number(resultData.result.gov_gr.Maharashtra_board),
            central: Number(resultData.result.gov_gr.Central_board),
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculatePercentage = (count, total) => {
    return total > 0 ? (count / total) * 100 : 0;
  };

  const totalTrainingCount = Object.values(trainingCounts).reduce(
    (a, b) => a + b,
    0
  );
  const totalGrCount = Object.values(grCounts).reduce((a, b) => a + b, 0);

  return (
    <Fragment>
      <Container maxWidth="lg" className="trainGrContain">
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <h2>Live Trainings</h2>
            <Card className="card">
              <p>Total Trainings</p>
              <div className="details">
                <img alt="img" src={upcomingIcon} />
                <div className="count">
                  <div className="eventName">
                    <span className="">Upcoming Events</span>
                  </div>
                  <div className="progress">
                    <LinearProgress
                      variant="determinate"
                      className="linearProgress"
                      style={{
                        borderRadius: "5px",
                        height: "8px",
                        backgroundColor: "#b4e9d3",
                      }}
                      classes={{
                        bar: "progressBar",
                      }}
                      value={calculatePercentage(
                        trainingCounts.upcoming,
                        totalTrainingCount
                      )}
                    />
                  </div>
                </div>
                <div className="numberCount">
                  <span className="progressCount">
                    {trainingCounts.upcoming}
                  </span>
                </div>
              </div>
              <div className="details">
                <img alt="img" src={training} />
                <div className="count">
                  <div className="eventName">
                    <span className="">Past Trainings</span>
                  </div>
                  <div className="progress">
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(
                        trainingCounts.pastTrain,
                        totalTrainingCount
                      )}
                      style={{
                        borderRadius: "5px",
                        height: "10px",
                        backgroundColor: "#b4e9d3",
                      }}
                      classes={{
                        bar: "progressBar",
                      }}
                    />
                  </div>
                </div>
                <div className="numberCount">
                  <span className="progressCount">
                    {trainingCounts.pastTrain}
                  </span>
                </div>
              </div>
              <div className="details">
                <img alt="img" src={webinar} />
                <div className="count">
                  <div className="eventName">
                    <span className="">Past Webinars</span>
                  </div>
                  <div className="progress">
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(
                        trainingCounts.pastWeb,
                        totalTrainingCount
                      )}
                      style={{
                        borderRadius: "5px",
                        height: "8px",
                        backgroundColor: "#b4e9d3",
                      }}
                      classes={{
                        bar: "progressBar",
                      }}
                    />
                  </div>
                </div>
                <div className="numberCount">
                  <span className="progressCount">
                    {trainingCounts.pastWeb}
                  </span>
                </div>
              </div>
              <div className="details">
                <img alt="img" src={videos} />
                <div className="count">
                  <div className="eventName">
                    <span className="">Educational Videos</span>
                  </div>
                  <div className="progress">
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(
                        trainingCounts.videos,
                        totalTrainingCount
                      )}
                      style={{
                        borderRadius: "5px",
                        height: "10px",
                        backgroundColor: "#b4e9d3",
                      }}
                      classes={{
                        bar: "progressBar",
                      }}
                    />
                  </div>
                </div>
                <div className="numberCount">
                  <span className="progressCount">{trainingCounts.videos}</span>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6}>
            <h2>Live Govt. Circular/GR</h2>
            <Card className="card">
              <p>Total Govt.Circular/GR</p>
              <div className="details">
                <img alt="img" src={CentralBoard} />
                <div className="count">
                  <div className="eventName">
                    <span className="">Central Board</span>
                  </div>
                  <div className="progress">
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(
                        grCounts.central,
                        totalGrCount
                      )}
                      style={{
                        borderRadius: "5px",
                        height: "10px",
                        backgroundColor: "#a1c3f7",
                      }}
                      classes={{
                        bar: "progressGRBar",
                      }}
                    />
                  </div>
                </div>
                <div className="numberCount">
                  <span className="progressCount">{grCounts.central}</span>
                </div>
              </div>
              <div className="details">
                <img alt="img" src={MhBoard} />
                <div className="count">
                  <div className="eventName">
                    <span className="">Maharashtra State Board</span>
                  </div>
                  <div className="progress">
                    <LinearProgress
                      variant="determinate"
                      value={calculatePercentage(
                        grCounts.mhBoard,
                        totalGrCount
                      )}
                      style={{
                        borderRadius: "5px",
                        height: "10px",
                        backgroundColor: "#a1c3f7",
                      }}
                      classes={{
                        bar: "progressGRBar",
                      }}
                    />
                  </div>
                </div>
                <div className="numberCount">
                  <span className="progressCount">{grCounts.mhBoard}</span>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default TrainingGr;
